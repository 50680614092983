<template>
<!--  专家管理  -->
<div class="specialistManage">
  <div class="expert_header">
    <div style="display: flex;align-items: center;width: 100%">
      <span :style="{color:variables.topTitleColor,width:'45px'}">姓名：</span>
      <a-input allowClear placeholder="请输入" v-model="searchName" style="width: 200px;margin-right: 30px" @keyup.enter.native="searchBtn()"  />

<!--      <span :style="{color:variables.topTitleColor,width:'45px'}">科室：</span>-->
<!--      <a-select-->
<!--          allowClear-->
<!--          show-search-->
<!--          placeholder="请选择"-->
<!--          v-model="office"-->
<!--          style="width: 200px;margin-right: 20px"-->
<!--          @change="searchBtn">-->
<!--        <a-select-option v-for="(item,index) in OfficesList" :key="index" :value="item">-->
<!--          {{item}}-->
<!--        </a-select-option>-->
<!--      </a-select>-->

      <!-- <a-select v-model="addForm.salary" placeholder="请选择" show-search>
              <a-select-option v-for="item in SalaryList" :value="item.name">
                {{ item.name }}
              </a-select-option>
            </a-select> -->
    </div>
    <div style="display: flex">
      <a-button type="primary" @click="searchBtn" style="margin-right: 15px">
        查询
      </a-button>
      <a-button @click="searchChange">
        重置
      </a-button>
    </div>
  </div>
  <div class="titleTop">
    <div class="divSty">
      <div :style="{fontWeight: '600',width:'100%',color:variables.topNameColor,display:'flex',justifyContent: 'space-between',alignItems:'center'}">
        <span>参会人员管理</span>
        <div>
          <a-button @click="ImportItmes" style="margin-right: 15px;margin-right: 15px;border: 1px solid #45A5E6;color: #45A5E6;">
            <a-icon type="vertical-align-bottom" />
            导入
          </a-button>

          <a-button @click="addBtn" type="primary">
            + 新建
          </a-button>
<!--          <a-button type="primary" @click="ImportItmes" style="margin-right: 15px;margin-left: 15px;">-->
<!--            导入-->
<!--          </a-button>-->

        </div>

      </div>
    </div>
  </div>
  <div class="expert_table">

    <!-- <a-button @click="addBtn" type="dashed" style="min-width: 100%;margin-bottom: 20px;">
      + 添加
    </a-button> -->
    <a-table style="margin-bottom: 5px"
             :scroll="{ x: 1000 }"
             :showHeader="true"
             :columns="columns"
             :data-source="InfoTable"
             :pagination="false" rowKey="id"
    >
      <span slot="icon" slot-scope="text,item">
        <img :src="item.headimg" style="height: 50px;width: 50px" alt="">
      </span>
      <!-- <span slot="titles" slot-scope="text,item">
        <div >
          <div style="display: flex">
            <span style="font-weight: bold;color: #333333;margin-right: 20px">{{item.name}}</span>
            <div style="color: #929292">
              <div>{{item.hospital}}&nbsp;&nbsp;</div>
              <div>{{item.hospitalOffice}}&nbsp;&nbsp;{{item.title}}</div>
            </div>
          </div>

        </div>
      </span> -->
      <!-- <span slot="time" slot-scope="text,item">
        <span style="color: #929292">创建时间：{{item.createdTime}}</span>
        <p></p>
        <span style="color: #929292">简介上次修改时间：{{item.upUpdatedTime ? item.upUpdatedTime:item.createdTime}}</span>
      </span> -->

      <span slot="operate" slot-scope="text,item">
<!--        <a style="margin-right: 8px"   @click="memberBtn(item)">{{item.memberId ===null? '绑定账号' :item.memberId ===''?'绑定账号':item.memberId ==='null'? '绑定账号':'查看账号'}}</a>-->
<!--        <a style="margin-right: 8px"  v-if="item.isConsultation===0" @click="meetAdd(item.id)">添加到会诊专家</a>-->
            <a style="margin-right: 8px" @click="editBtn(item)">编辑</a>
            <a style="margin-right: 8px" @click="resumeBtn(item)">简介</a>
            <a style="margin-right: 8px" @click="accessoryBtn(item.id)">附件</a>
            <a-popconfirm
                title="是否确认删除？"
                ok-text="是"
                cancel-text="否"
                @confirm="delTableBtn(item)">
                 <a>删除</a>
               </a-popconfirm>
      </span>

    </a-table>


    <div style="display: flex;justify-content: center;margin-top: 20px;">
      <a-pagination :current="pagination.current"
                    :total="pagination.total"
                    show-quick-jumper
                    @change="pageChange"/>
    </div>
  </div>

  <!-- 新增/修改 -->
  <ExpertViewone
      v-if="addShow"
      :visible="addShow"
      :addTitle="addTitle"
      :formPage="formPage"
      :expertId="tableId"
      :addForm="addForm"
      v-on:closeMain="closeMain"
  />
<!--  <a-drawer-->
<!--      :title="addTitle"-->
<!--      :width="600"-->
<!--      :visible="addShow"-->
<!--      :body-style="{ paddingBottom: '80px' }"-->
<!--      @close="addClose()"-->
<!--  >-->
<!--    <a-form-model-->
<!--        ref="addItem"-->
<!--        :label-col="labelCol"-->
<!--        :model="addForm"-->
<!--        :rules="addRules"-->
<!--        :wrapper-col="wrapperCol"  >-->
<!--      <a-form-model-item label="头像1" prop="headImg">-->
<!--        <div>-->
<!--          <div style="display: flex">-->
<!--            <div class="uploadImg">-->
<!--              <input-->
<!--                  id="inputClear"-->
<!--                  ref="addImg"-->
<!--                  accept="image/*"-->
<!--                  style="width: 100%;height:100%;opacity: 0;"-->
<!--                  type="file"-->
<!--                  @change="uploadImg('addImg')"/>-->
<!--            </div>-->
<!--            <div class="upload_gutter">-->
<!--              <img-->
<!--                  v-if="addForm.headImg"-->
<!--                  :src="addForm.headImg"-->
<!--                  alt=""-->
<!--                  style="width: 100%;"-->
<!--              />-->
<!--            </div>-->
<!--          </div>-->
<!--          <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>-->
<!--        </div>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="头像2">-->
<!--        <div>-->
<!--          <div style="display: flex">-->
<!--            <div class="uploadImg">-->
<!--              <input-->
<!--                  id="inputClear2"-->
<!--                  ref="addImgRect"-->
<!--                  accept="image/*"-->
<!--                  style="width: 100%;height:100%;opacity: 0;"-->
<!--                  type="file"-->
<!--                  @change="uploadImg('addImgRect')"/>-->
<!--            </div>-->
<!--            <div class="upload_gutter2">-->
<!--              <img-->
<!--                  v-if="addForm.headImgRect"-->
<!--                  :src="addForm.headImgRect"-->
<!--                  alt=""-->
<!--                  style="width: 100%;height:100%"/>-->
<!--            </div>-->
<!--          </div>-->
<!--          <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>-->
<!--        </div>-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="姓名" prop="name">-->
<!--        <a-input v-model="addForm.name" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="手机号" prop="phone">-->
<!--        <a-input v-model="addForm.phone" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="省份城市" prop="province">-->
<!--        <a-cascader-->
<!--            v-model="addForm.province"-->
<!--            :options="hospitaloPtions"-->
<!--            placeholder="请选择"-->
<!--        />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="医院/单位" prop="hospital">-->
<!--        <a-input v-model="addForm.hospital" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="科室/部门" prop="department">-->
<!--        <a-input v-model="addForm.department" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
<!--      <a-form-model-item label="职称/职位"  prop="job">-->
<!--        <a-input v-model="addForm.job" placeholder="请输入" />-->
<!--      </a-form-model-item>-->
<!--    </a-form-model>-->


<!--    <div-->
<!--        :style="{-->
<!--          position: 'absolute',-->
<!--          right: 0,-->
<!--          bottom: 0,-->
<!--          width: '100%',-->
<!--          borderTop: '1px solid #e9e9e9',-->
<!--          padding: '10px 16px',-->
<!--          background: '#fff',-->
<!--          textAlign: 'right',-->
<!--          zIndex: 1,-->
<!--        }"-->
<!--    >-->
<!--      <a-button :style="{ marginRight: '8px' }" @click="addClose">-->
<!--        取消-->
<!--      </a-button>-->

<!--      &lt;!&ndash;新增  &ndash;&gt;-->
<!--      <a-button type="primary" @click="addOk">-->
<!--        确认-->
<!--      </a-button>-->
<!--    </div>-->
<!--  </a-drawer>-->


  <!-- 绑定账号 -->
  <a-drawer
      title="绑定账号"
      width="900"
      :visible="scopeShow"
      @close="()=>{this.scopeShow = false;this.memberLogName='';this.memberLogTel='';this.telData=[]}"
  >
    <div class="selectPersonnel" >
      <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;margin: 5px 0 5px 0">
        <a-form-model ref="addItem" :label-col="labelCol" :model="memberForm"  :wrapper-col="wrapperCol"  >
          <span style="display: flex">
            <a-form-model-item label="姓名" >
            <a-input v-model="memberForm.name" style="width: 200px;margin-right: 10px" disabled  />
          </a-form-model-item>
            <a-form-model-item label="医院" >
            <a-input v-model="memberForm.hospital" style="width: 200px;margin-right: 10px" disabled placeholder="请输入" />
          </a-form-model-item>
          </span>
          <span style="display: flex">
            <a-form-model-item label="职称" >
            <a-input v-model="memberForm.job" style="width: 200px;margin-right: 10px" disabled placeholder="请输入" />
          </a-form-model-item>
            <a-form-model-item label="科室" >
            <a-input v-model="memberForm.hospitalOffice!==null?memberForm.hospitalOffice:memberForm.department" style="width: 200px;margin-right: 10px" disabled placeholder="请输入" />
          </a-form-model-item>
          </span>
          <span style="display: flex">
            <a-form-model-item label="状态" >
            <a-input v-model="memberForm.state" :style="{'color': memberForm.state==='未绑定'?' #CC0000':'#00CC33'}"   style="width: 200px" disabled placeholder="请输入" />
          </a-form-model-item>
          </span>

        </a-form-model>
      </div>
    </div>
    <div class="selectedStaff" style="margin: 10px 0 40px;">
      <div style="display: flex;justify-content: space-between">
        <h3>{{memberForm.memberId===null?'查询注册信息':'注册信息'}}</h3>
      </div>
<!--      <div style="display: flex;align-items: center;width: 60%;justify-content: space-between;margin: 5px 0 5px 0">-->
<!--        <div> 姓名: &nbsp;<a-input v-model="memberLogName" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchLogTel()"/></div>-->
<!--        <div> 手机号: &nbsp;<a-input v-model="memberLogTel" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchLogTel()"/></div>-->
<!--        <a-button type="primary"  @click="searchLogTel"> 查询</a-button>-->
<!--      </div>-->
      <a-table :columns="memberColumn" :data-source="telData" :rowKey="(record,index)=>{return index}"   :pagination="false"
      >
            <span slot="operate" slot-scope="text,item">
              <a-popconfirm
                  title="确定解绑?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delMember(item.id)"
                  @cancel="cancel"
                  v-if="memberForm.memberId!==null &&memberForm.memberId!=='null'"
              >
                <a>解绑</a>
              </a-popconfirm>
              <a v-else  @click="memberOk(item.id)">选择</a>
            </span>
      </a-table>
    </div>
    <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
    >
      <a-button style="marginRight: 8px" @click="()=>{this.scopeShow = false;this.memberLogName='';this.memberLogTel='';this.telData=[]}">
        取消
      </a-button>
      <a-button type="primary"
                @click="()=>{this.scopeShow = false;this.memberLogName='';this.memberLogTel='';this.telData=[]}">
        确认
      </a-button>
    </div>
  </a-drawer>

  <!-- 附件 -->
  <a-drawer
      title="附件"
      width="800"
      :visible="accessoryShow"
      @close="()=>{this.accessoryShow = false}"
  >
    <div class="selectPersonnel" >
      <div style="display: flex;justify-content: space-between">
        <h4>上传附件</h4>
      </div>
      <a-form-model
          ref="accessorAdd"
          :label-col="labelCol"
          :model="accessorForm"
          :rules="rulesAccessor"
          :wrapper-col="wrapperCol"  >
        <a-form-model-item prop="enclosure">
          <span slot="label">
            <span style="color: red">* </span><span>备份附件</span>
          </span>
          <div class="upload_File">
            <input style=" cursor:pointer;width:100%;height: 100%;opacity: 0;" ref="addFile" type="file"  @change="addFile"  />
          </div>
          <span style="font-size: xx-small">{{accessorForm.fileName}}</span>
        </a-form-model-item>
        <a-form-model-item prop="name">
           <span slot="label">
            <span style="color: red">* </span><span>备份名称</span>
          </span>
          <a-input v-model="accessorForm.name" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
      <div style="display: flex;justify-content: center">
        <a-button  type="primary" @click="accessorAddOk">提交</a-button>
      </div>
    </div>
    <div class="selectedStaff" style="margin: 10px 0;">
      <div style="display: flex;justify-content: space-between">
        <h4>附件列表</h4>
      </div>
      <a-table :columns="accessoryColumn"
               :data-source="accessoryData"
               :rowKey="(record,index)=>{return index}"
               :pagination="false"
      >
            <span slot="operate" slot-scope="record,text,item">
              <a @click="downAccessory(record.enclosure)">下载</a>

               <a-popconfirm
                   style="margin-left: 10px;"
                   title="是否确认删除？"
                   ok-text="是"
                   cancel-text="否"
                   @confirm="delFileBtn(record)">
                 <a>删除</a>
               </a-popconfirm>
            </span>
      </a-table>
    </div>
  </a-drawer>

  <!-- 简介 -->
  <a-drawer
      title="简介"
      width="600"
      :visible="resumeShow"
      @close="()=>{this.resumeShow = false}"
  >
    <a-form-model
        ref="addItem"
        :label-col="{span: 3}"
        :rules="rules"
        :wrapper-col="{span: 16}"
        :model="formData">
      <a-form-model-item  prop="into">
        <span slot="label">
          <span style="color: red">* </span>简介
        </span>
          <a-textarea auto-size
                      placeholder="请输入"
                      v-model="formData.into"
                      style="min-height: 400px;font-size: 16px"/>
          <span style="color: #8c8a8a;font-size: 12px">简介上次修改时间:
            {{formData.upUpdatedTime ? formData.upUpdatedTime : formData.createdTime}}
          </span>
      </a-form-model-item>
    </a-form-model>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'left',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="()=>{this.resumeShow = false}">
        取消
      </a-button>
       <a-popconfirm title="是否确认更新简介" ok-text="是" cancel-text="否" @confirm="updateOk">
         <a-button type="primary" :style="{ marginRight: '8px' }" >
           确认
         </a-button>
      </a-popconfirm>

    </div>
  </a-drawer>

  <a-modal
      :zIndex="1005"
      title="位置修改填写"
      :visible="wzVisible"
      @ok="wzOk"
      @cancel="() => wzVisible = false"
  >
    <div style="font-size: 13px;">
      示例：如专家原医院由山东省立医院改为齐鲁医院，内容则输入：齐鲁医院    </div>
    <a-form-model ref="wzItem" :rules="wzRules"         :label-col="{ span: 6 }"
                  :wrapper-col="{ span: 18 }" :model="wzData" >
      <a-form-model-item style="margin-top: 20px" label="填写修改后内容" prop="laterContent">
        <a-textarea auto-size placeholder="请输入" v-model="wzData.laterContent" style="width:100%;min-height: 120px;font-size: 16px"/>
      </a-form-model-item>
    </a-form-model>
  </a-modal>

  <!-- 导入框 -->
  <a-modal
      width="600px"
    title="从Excel导入"
    :visible="importModal"
    :footer="null"
    @cancel="importClose"
    >
    请选择要导入的数据文件，<a @click="downloadTemplate">点击下载模板</a>
    <!-- <div style="margin:10px 0 15px 0;position: relative;"  class="uploadImg">
      <input style="width:100%;height: 100%;position: absolute;right:-20px" id="uploadFile" type="file" @change="changeFileElsx" ref="uploadFile_inputFile"

      accept=".xlsx,.xls" />
    </div> -->
    <div style="display: flex;justify-content: space-between;align-items: flex-end;;">
      <div>
        <div class="upload_2File">
          <input :disabled="loadImg" style=" cursor:pointer;width:100%;height: 100%;opacity: 0;" id="uploadFile" type="file" @change="changeFileElsx" ref="uploadFile_inputFile"   />
        </div>
        <div style="margin-top:10px;" v-if="changeFile!==''">
         文件： {{ changeFile }}
        </div>
        <!-- <span style="font-size: 10px">支持扩展名：.psd</span> -->
        <div style="color: red" v-if="loadImg">
          上传中...
        </div>
        <!-- <div style="font-size: 8px;width: 600px">{{addForm.psdFile}}</div> -->
      </div>
      <div>
        <a-button v-if="changeFile" @click="cancelImport" style="margin-right:20px">取消</a-button>
        <a-button type="primary" @click="importOk"> <a-icon type="vertical-align-bottom"/>开始导入</a-button>
      </div>
    </div>
    <a-collapse v-model="activeKey" style="margin-top:20px;" class="collapse">
      <a-collapse-panel key="1" header="查看文件上传要求">
        <p>1.支持Excel 2007及以上版本文件</p>
        <p>2.为保证数据顺利导入，请下载导入模板</p>
        <p>3.一次导入数据不能超过500行</p>
        <div class="blueTable">
          <a-table :columns="VIPcolumns"
                   :data-source="VIPdata"
                   size="small"
                   :pagination="false"
                   :scroll="{ x: 'calc(800px + 50%)'}">
            <span slot="name" slot-scope="text, record">
                <span style="color: red">{{record.name}}</span>
              </span>
            <span slot="sex" slot-scope="text, record">
                <span style="color: red">{{record.sex}}</span>
              </span>
            <span slot="province" slot-scope="text, record">
                <span style="color: red">{{record.province}}</span>
              </span>
            <span slot="city" slot-scope="text, record">
                <span style="color: red">{{record.city}}</span>
              </span>
            <span slot="unit" slot-scope="text, record">
                <span style="color: red">{{record.unit}}</span>
              </span>
            <span slot="phone" slot-scope="text, record">
                <span style="color: red">{{record.unit}}</span>
              </span>
          </a-table>
        </div>
      </a-collapse-panel>
    </a-collapse>



    <!-- <a-menu
      style="width: 100%"
      :default-selected-keys="['1']"
      :open-keys.sync="openKeys"
      mode="inline"
      class="menuList"
    >
      <a-sub-menu key="sub1" >
        <span slot="title"><span>查看文件上传要求</span></span>
        <a-menu-item-group key="g1">
          <template slot="title">
            <p>1.支持Excel 2007及以上版本文件</p>
          <p>2.为保证数据顺利导入，请下载导入模板</p>
          <p>3.一次导入数据不能超过500行</p>
          </template>
        </a-menu-item-group>
      </a-sub-menu>
    </a-menu> -->


  </a-modal>
</div>
</template>

<script>
import variables from "@/assets/base.scss";
import City from "@/assets/city.json";
import headerConfig from "@/service/api_header_config";
import moment from "moment";
const plainOptions = ['医护人员', '非医护人员/医学生'];
import {update,update_File} from '@/utils/update'
import {
  getAddInfo,
  enclosureInsert,
  selectEnclosureAll,
  delAddInfo,
  selectOfficesl,
  postAddInfo,
  putAddInfo,
  delEnclosureInsert,
  postImportMember,
  exportTemplate
} from "@/service/participants";
import ExpertViewone from "@/components/meet/ExpertViewone";


export default {
  name: "Expert",
  components: {ExpertViewone},
  data(){
    let editImageValid = (rule, value, callback) => { // 自定义校验规则-新增图片
      if (this.editForm.name) {
        callback();
      }else {
        callback(new Error('请输入姓名'));
      }
    };
    return {
       text: `A dog is a type of animal. A dog is typically kept as a pet or as an individual who can be trained to work as a guardian and protector. One of the most common breeds of dog is the Bulldog.`,
      loadImg:false,
      current: ['mail'],
      openKeys: [''],
      activeKey: ['1'],
      variables,
      office:undefined,
      wzRules: {
        laterContent: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      rules: {
        // laterContent: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      rulesAccessor: {
        // enclosure: [{required: true, message: '请上传', trigger: 'change'}],
        // name: [{required: true, message: '请上传', trigger: 'blur'}],
      },
      wzData: {
        laterContent: ''
      },
      wzVisible: false,
      plainOptions,
      formData:{
        hospital:undefined,
        tags:[]
      },
      value1: '医护人员',  //用与医护类型的默认框
      value2: '非医护人员/医学生',
      yihutype:'医护人员',   //初始值一定要设置医护人员


      City:City,
      addShow: false,
      editShow: false,
      tagsShow:false,
      scopeShow:false,
      accessoryShow: false,
      resumeShow:false,
      inputValue: '',
      addForm: {
      },
      editForm: {
        department2: '',
        job2: '',
        province2:'',
      },
      telData:[],
      memberForm:{},
      hospitaloPtions:[],
      hospitalNameList:[],
      jobTitleList:[],
      hospitalOfficesList:[],
      addTitle:'',
      formPage:'SpecialistManage',
      tableId:null,
      addRules: {
        name: [{ required: true,message: '请输入', trigger: 'blur'}],
        phone: [{ required: true,message: '请输入', trigger: 'blur'}],
        province: [{ required: true, message: '请选择', trigger: 'change' }],
        enclosure: [{required: true, message: '请上传', trigger: 'blur'}],
        headImg: [{required: true, message: '请上传', trigger: 'blur'}],
        hospital: [{ required: true, message: '请输入', trigger: 'change' }],
        department: [{required: true, message: '请输入', trigger: 'change'}],
        into: [{required: true, message: '请输入', trigger: 'blur'}],
        job: [{required: true, message: '请输入', trigger: 'change'}],
      },
      searchName:undefined,
      InfoTable:[],
      columns:[
        {
          title: '头像',
          // width:'5%',
          align: 'center',
          scopedSlots: {customRender: 'icon'},
        },
        {
          title: '姓名',
          dataIndex: 'name',
          // width: '40%',
          // scopedSlots: {customRender: 'titles'},
        },
        {
          title: '工作单位',
          dataIndex: 'hospital',
        },
        {
          title: '所在部门',
          dataIndex: 'hospitalOffice',
        },
        {
          title: '职称/职位',
          dataIndex: 'title',
        },
        {
          title: '简历上次修改时间',
          dataIndex: 'upUpdatedTime',
        },
        {
          title: '创建时间',
          dataIndex: 'createdTime',
          // width: '25%',
          // scopedSlots: {customRender: 'time'},
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {customRender: 'operate'},
        },
      ],
      VIPcolumns:[
        {
          title: '姓名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
        {
          title: '性别',
          dataIndex: 'sex',
          scopedSlots: { customRender: 'sex' },
        },
        {
          title: '身份证号',
          dataIndex: 'sfzh',
        },
        {
          title: '出生日期',
          dataIndex: 'birthday',
        },
        {
          title: '省份',
          dataIndex: 'province',
          scopedSlots: { customRender: 'province' },
        },
        {
          title: '城市',
          dataIndex: 'city',
          scopedSlots: { customRender: 'city' },
        },
        {
          title: '工作单位',
          dataIndex: 'unit',
          scopedSlots: { customRender: 'unit' },
        },
        {
          title: '所在部门',
          dataIndex: 'szbm',
        },
        {
          title: '职称/职位',
          dataIndex: 'zczw',
        },
        {
          title: '手机号',
          dataIndex: 'phone',
          scopedSlots: { customRender: 'phone' },
        },
        {
          title: '电子邮箱',
          dataIndex: 'email',
        },
        {
          title: '开户行',
          dataIndex: 'khh',
        },
        {
          title: '银行卡号',
          dataIndex: 'yhkh',
        },
      ],
      VIPdata:[
        {
          name:'必填',
          sex:'必填',
          sfzh:'非必填',
          birthday:'非必填',
          province:'必填',
          city:'必填',
          unit:'必填',
          szbm:'非必填',
          zczw:'非必填',
          phone:'必填',
          email:'非必填',
          khh:'非必填',
          yhkh:'非必填',
        }
      ],
      memberData:[],
      memberColumn:[
          {
        title: '姓名',
        dataIndex: 'name',
      },
        {
          title: '手机',
          dataIndex: 'tel',
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
        },
        // {
        //   title: '操作',
        //   scopedSlots: {customRender: 'operate'},
        // },
      ],
      accessoryData:[],
      accessorForm:{},
      accessoryColumn:[
        {
          title: '备份名称',
          dataIndex: 'name',
        },
        {
          title: '上传人',
          dataIndex: 'createdByName',
        },
        {
          title: '上传时间',
          dataIndex: 'createdTime',
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operate'},
        },],
      memberLogName:'',
      memberLogTel:'',
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      importModal:false,
      changeFile:'',
      result: [],
      expertId: null,
      editData: {},
      editStatus: null,
      OfficesList:[],
      exportformData:'',
    }
  },

  created() {
    this.getLogData();
    this.getPCH();

  },
  methods: {
    async downloadTemplate(){
      let data = {
        ExelName:'参会vip人员导入模板'+new Date().getTime(),
      }
      const info = await  exportTemplate(data)
      // if (info.code === 200){
      //   // this.InfoTable = info.data;
      //   // this.pagination.total=info.count;

      //   // this.InfoTable.forEach((value,index)=>{
      //   //   if (value.tags!==null){
      //   //     value.tag=value.tags.split(/[，；,;、]/)
      //   //   }
      //   // })
      //   // this.getSelectOfficesl();
      // }
    },
    async changeFileElsx() {
      this.loadImg = true
      let inputDOM = this.$refs.uploadFile_inputFile.files[0];
      let formData = new FormData()
      formData.append('file', inputDOM)
      this.changeFile = inputDOM.name
      this.exportformData = formData
      this.loadImg = false
    },
    async importOk() {
      if (this.exportformData) {
        const response = await postImportMember(this.exportformData)
        if (response.code === 0) {
          //上传成功后将value制空防止上传一样名称文件时不触发change
          document.getElementById('uploadFile').value = null;
          //刷新列表
          await this.getLogData();
          this.$message.success(response.message)
          this.changeFile = ''
        } else {
          this.$message.warning(response.message)
          // document.getElementById('uploadFile').value = null;
          // this.exportformData = ''
          // this.changeFile = ''
        }
      } else {
        this.$message.warning("请先选择文件~")
      }
    },
    async cancelImport(){
      document.getElementById('uploadFile').value = null;
      this.exportformData = ''
      this.changeFile = ''
    },
    ImportItmes(){
      this.importModal = true
    },
    importClose(){
      this.importModal = false
    },
    closeMain(val) {
      this.addShow = val;
    },
    removeFileExtension(fileName) {
      return fileName.split('.').slice(0, -1).join('.');
    },
    async wzOk () {
    //   this.$refs.wzItem.validate(async valid => {
    //     if (valid) {
    //       const edit = await putExpert(this.editData)
    //       if (edit.code===0){
    //         this.$message.success('修改成功')
    //         if (this.editStatus === 'add') {
    //           this.addForm={
    //             tags:[]
    //           }
    //           this.getLogData()
    //         } else if (this.editStatus === 'edit'){
    //           this.editForm={}
    //           // this.editShow = false
    //           this.getLogData()
    //         }
    //         const data = {
    //           expertId: this.expertId,
    //           laterContent: this.wzData.laterContent
    //         }
    //         const res = await postExpertAccessorPut(data)
    //         if (res.code === 0) {
    //           this.$message.success('填写成功')
    //           if (this.editStatus === 'add') {
    //             this.addShow = false
    //           } else if (this.editStatus === 'edit') {
    //             this.editShow = false
    //           }
    //           this.wzData.laterContent = ''
    //           this.wzVisible = false
    //         } else {
    //           this.$message.warning(res.message)
    //         }
    //         // this.wzVisible = true
    //       }
    //
    //     }
    //   })
    //
    },
    addClose() {
      this.addShow = false;
      this.$refs.addItem.resetFields()
      this.$set(this.addForm, 'headImgRect', "")
      this.$set(this.addForm, 'province', undefined)
    },
    //获取专家
    async getLogData(){
      const data={
        name:this.searchName,
        office:this.office,
        page:this.pagination.current,
        pageSize:this.pageSize,
      }
      const info = await  getAddInfo(data)
      if (info.code === 200){
        this.InfoTable = info.data;
        this.pagination.total=info.count;

        this.InfoTable.forEach((value,index)=>{
          if (value.tags!==null){
            value.tag=value.tags.split(/[，；,;、]/)
          }
        })
        this.getSelectOfficesl();
      }
    },
    //删除专家
    delTableBtn(row) {
      let data = {
        id: row.id,
      }
      this.delAddInfoList(data)
    },

    getPCH(){
      let province = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.hospitaloPtions = province
    },


    updateOk() {
      if(this.formData.into){
        let data = {
          id:this.tableId,
          intro:this.formData.into
        }
        this.putAddInfoList(data,'简介');
      }else {
        this.$message.warning("请先输入内容！");
      }
    },

    showInput() {
      this.tagsShow = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
     handleInputConfirm(type) {
      if (this.inputValue!==''){
        switch (type){
          case 'add':
            this.addForm.tags.push(this.inputValue)
            break;
          case 'edit':
            this.editForm.tags.push(this.inputValue)
        }
        this.tagsShow = false;
        this.inputValue=''
      }else {
        this.$message.warning('请输入内容')
      }
    },

    searchBtn(){
      this.pagination.current=1;
      this.getLogData();
    },
    searchChange(){
      this.pagination.current = 1;
      this.searchName = '';
      this.office = undefined;
      this.getLogData();
    },
    pageChange(current){
      this.pagination.current=current
      this.getLogData()
    },
    async getSearchMember(name,tel,memberId){
      const info = await getMeetMemberTel(name,tel,memberId)
      if (info.code===0){
        this.telData=info.data
        if (info.data.length===0){
          this.$message.warn('查询为空')
        }
      }else {
        this.$message.warning(info.message)
      }
    },

    searchLogTel(){
      this.getSearchMember(this.memberLogName,this.memberLogTel)
      this.$set(this.memberForm,'memberId',null)
    },

    async memberOk(memberID){
      const data={
        expertId:this.memberForm.id,
        memberId:memberID
      }
      const add = await putExpertMember(data)
      if (add.code===0){
        this.$message.success('操作成功')
        this.memberForm.memberId=memberID
        this.getSearchMember('','',memberID)
        this.getLogData()
        this.$set(this.memberForm,'state','已绑定')
      }
    },

     memberBtn(row){
      this.memberForm=JSON.parse(JSON.stringify(row))
      this.scopeShow=true
      if (row.memberId!==null && row.memberId!=='null' && row.memberId!==''){
        this.getSearchMember('','',row.memberId)
        this.memberForm.state='已绑定'
      }else {
        this.memberForm.state='未绑定'
      }
    },
    //添加会诊专家
    async meetAdd(id){
      const data={
        expertId:id
      }
      const add = await  postMeetExpert(data)
      if (add.code===0){
        this.getLogData()
        this.$message.success('操作成功')
      }
    },
    //上传图片
    async uploadImg(type) {
      var inputClear = document.querySelector('#inputClear')
      var inputClear2 = document.querySelector('#inputClear2')
      var inputClear3 = document.querySelector('#inputClear3')
      var inputClear4 = document.querySelector('#inputClear4')
      let inputDOM = this.$refs[type].files[0];
      if(inputDOM.size <512000){
        const image =await update(inputDOM)
        if (image.code === 0){
          switch (type){
            case 'addImg': //头像1
              this.$set(this.addForm,'headImg',image.data.url)
              break;
            case 'addImgRect': //头像2
              this.$set(this.addForm,'headImgRect',image.data.url)
              break;
            case 'editImg': //修改 - 头像1
              this.$set(this.editForm,'headImg',image.data.url)
              break;
            case 'editImgRect': //修改 - 头像2
              this.$set(this.editForm,'headImgRect',image.data.url)
              break;
          }
          this.$message.success('上传成功')
          if (inputClear.value !== '') {
            inputClear.value = ''
          } else if (inputClear2.value !== '') {
            inputClear2.value = ''
          } else if (inputClear3.value !== '') {
            inputClear3.value = ''
          } else if (inputClear4.value !=='') {
            inputClear4.value =''
          }
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
      else {
        this.$message.warning('仅上传500k内图片')
      }
    },

    // async uploadImg(type) {
    //   var inputTest = document.querySelector('#inputTest')
    //   // type只是传一个状态而已，说但是不知道怎么说，我感觉就是需要刷新
    //   let inputDOM = this.$refs[type].files[0];
    //   console.log(this.$refs[type])
    //   if(inputDOM.size < 512000 ){
    //     const image = await update(inputDOM)
    //     // if (this.form.images.length > 9) {
    //     //   this.$message.warning('太多了')
    //     // }
    //     if (image.code === 0 && this.form.images.length < 9 ){
    //       switch (type){
    //         case 'addImg':
    //           this.form.images.push(image.data.url)
    //           // console.log(image.data.url)
    //           // console.log(this.form)
    //           break;
    //       }
    //       this.$message.success('上传成功')
    //     }
    //     else {
    //       this.$message.warning('最多只能上传9张图片')
    //     }
    //   }
    //   else{
    //     this.$message.warning('仅上传500k内图片')
    //   }
    //   inputTest.value =''
    // },




    //删除附件
    async delFileBtn(row) {
      let data = {
        id: row.id,
      }
      const info = await delEnclosureInsert(data)
      if (info.code === 200) {
        this.$message.success("删除成功！")
        this.getAccessory(this.accessorId)
      }
    },

    //获取专家附件
    async getAccessory(id){
      const info = await selectEnclosureAll(id)
      if (info.code === 200){
        this.accessoryData = info.data;
      }
    },
    accessoryBtn(id){
      this.getAccessory(id)
      this.accessorId=id
      this.accessoryShow=true
    },
    downAccessory(url){
      window.open(url)
    },
    async accessorAddOk(){
      // this.$refs.accessorAdd.validate(async valid =>{
        if(this.accessorForm.name && this.accessorForm.enclosure) {
          const form=this.accessorForm
          const data={
            expertId:this.accessorId,
            name:form.name,
            enclosure:form.enclosure
          }
          const add = await enclosureInsert(data)
          if (add.code===200){
            this.accessorForm.name=''
            this.accessorForm.enclosure=''
            this.accessorForm.fileName=''
            this.getAccessory(this.accessorId);
            this.$message.success('提交成功')
          }else {
            this.$message.warning('上传失败，请重试')
          }
        }else {
          this.$message.error("附件和附件名称不能为空！");
          return false;
        }
      // })
    },
    async addFile(){
      let inputDOM = this.$refs.addFile.files[0];
      const add = await update_File(inputDOM)
      if (add.code === 0) {
        this.$set(this.accessorForm,'enclosure',add.data.url)
        let fileName = this.removeFileExtension(inputDOM.name);
        this.$set(this.accessorForm,'fileName',inputDOM.name)
        this.$set(this.accessorForm,'name',fileName)
        this.$refs.addFile.value=null
        console.log(this.accessorForm)
        this.$message.success('上传成功')
      } else {
        this.$message.error("上传失败");
      }
    },
    addBtn(){
      this.addTitle = '新建';
      this.tableId = null;
      this.addShow = true
      this.yihutype = '医护人员'

    },
    addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const form = this.addForm;
          let data;
          if(this.addTitle === '新建'){ //新增
            data = {
              "name": form.name, //姓名
              "headimg": form.headImg, //头像1
              "headimgRect": form.headImgRect, //头像2
              "province": form.province[0], //省份
              "city": form.province[1], //城市
              "hospital": form.hospital, //医院
              "hospitalOffice": form.department, //科室
              "title": form.job, //职称
              "phone": form.phone, //手机号
              // "intro": form.into, //简介
              // "tags": this.inputValue, //标签
            }
            this.postAddInfoList(data);
          }else if(this.addTitle === '编辑'){
            data = {
              "id":this.tableId,
              "name": form.name, //姓名
              "headimg": form.headImg, //头像1
              "headimgRect": form.headImgRect, //头像2
              "province": form.province[0], //省份
              "city": form.province[1], //城市
              "hospital": form.hospital, //医院
              "hospitalOffice": form.department, //科室
              "title": form.job, //职称
              "phone": form.phone, //手机号
              // "intro": form.into, //简介
              // "tags": this.inputValue, //标签
            }

            this.putAddInfoList(data,'编辑');
          }

        }
      })
    },


    //新增vip
    async postAddInfoList(data) {
      const response = await postAddInfo(data)
      if(response.code === 200){
        this.$message.success("新增成功！");
        await  this.getLogData(); //刷新页面
        this.addClose();
      }
    },

    //修改
    async putAddInfoList(data,type) {
      const response = await putAddInfo(data)
      if(response.code === 200){
        this.$message.success("修改成功！");
        await  this.getLogData(); //刷新页面

        if(type === '编辑'){
          // 关闭对话框
          this.addClose();
        }else if(type === '简介'){
          this.resumeShow = false;
        }
      }
    },
    //删除
    async delAddInfoList(data) {
      const response = await delAddInfo(data)
      if(response.code === 200){
        this.$message.success("删除成功！");
        await  this.getLogData(); //刷新页面
      }
    },

    //获取科室列表
    async getSelectOfficesl() {
      const response = await selectOfficesl()
      if(response.code === 200){
        this.OfficesList = response.data.filter(item => item !== null);

        // this.OfficesList = response.data.splice(1,response.data.length-1)
        // console.log("??",this.OfficesList)
      }
    },


    async editSUbmit (data) {
      const edit = await putExpert(data)
      if (edit.code===0){
        this.addForm={
          tags:[]
        }
        this.$message.success('修改成功')
        this.getLogData()
        this.addShow=false
      }
    },
    // 简介
    resumeBtn(row) {
      this.tableId = row.id;
      this.formData = {
        into:row.intro,
        upUpdatedTime:row.upUpdatedTime,
        createdTime:row.createdTime,
      }
      this.resumeShow = true;
    },

    // 编辑框获取数据
     editBtn(row) {
      this.tableId = row.id;
      // this.addForm = {
      //   id:this.tableId,
      //   headImg:row.headimg,
      //   headImgRect:row.headimgRect,
      //   name:row.name,
      //   province:[row.province,row.city],
      //   hospital:row.hospital,
      //   phone:row.phone,
      //   department:row.hospitalOffice,
      //   job:row.title,
      // }
       this.addTitle = '编辑';
       this.addShow = true;
    },


    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const form = this.editForm
          this.expertId = form.id
          if (this.yihutype == '医护人员' || this.yihutype == null) {
            const data={
              id:form.id,
              byId:JSON.parse(user_info).id,
              headImg:form.headImg,
              name:form.name,
              hospital:form.hospital,
              headImgRect:form.headImgRect,
              into:form.into,
              tags:!form.tags || form.tags.length===0?null:form.tags.join(","),
              province:form.province[0],
              city:form.province[1],
              job:form.job,
              department: form.department.join("-"),
              type:"医护人员",
              memberId: form.memberId,
              edit: 'edit'
          }
          // const edit = await putExpert(data)
          // if (edit.code===0){
          //   this.editForm={}
          //   this.$message.success('修改成功')
          //   this.getLogData()
          //   this.editShow=false
            this.editData = data
            if (this.editStatus === 'add') {
              this.wzData.laterContent = ''
            }
            this.editStatus = 'edit'
            // this.editShow = false
            this.wzVisible = true
          //   }
          }

          if (this.yihutype == '非医护人员/医学生') {
            const data={
              id:form.id,
              byId:JSON.parse(user_info).id,
              headImg:form.headImg,
              name:form.name,
              hospital:form.hospital2,
              headImgRect:form.headImgRect,
              into:form.into,
              tags:!form.tags ||form.tags.length===0?null:form.tags.join(","),
              province:"",
              city:"",
              job:form.job2,
              department: form.department2,
              type:"非医护人员/医学生",
              memberId: form.memberId,
              edit: 'edit'
            }
            this.editData = data
            if (this.editStatus === 'add') {
              this.wzData.laterContent = ''
            }
            this.editStatus = 'edit'
            // this.editShow = false
            this.wzVisible = true
          // const edit = await putExpert(data)
          // if (edit.code===0){
          //   this.editForm={}
          //   this.$message.success('修改成功')
          //   this.getLogData()
          //   this.editShow = false
          //   this.wzVisible=  true
          // }
          }
        }
      })
    },
    //删除专家
    async delTitle(id){
      const Info =await delExpert(id)
      if (Info.code === 0){
        await this.getLogData()
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败')
      }
    },
    //删除标签
    delTags(index,type){
      switch (type){
        case 'add':
          this.addForm.tags.splice(index, 1)
              break;
        case 'edit':
          this.editForm.tags.splice(index, 1)
              break;
      }
    },
    //置空绑定账号
    async delMember(){
      const data={
        expertId:this.memberForm.id,
        memberId:"0"
      }
      const del = await  putExpertMember(data)
      if (del.code===0){
        this.$message.success('操作成功')
        this.getLogData()
        this.$set(this.memberForm,'state','未绑定')
        this.memberForm.memberId=null
        this.telData=[]
      }
    },
    cancel(){
      this.$message.warning('取消操作')
    },
  },
}
</script>

<style lang="scss" scoped>
.upload_2File{
  // width: 150px;
  // border: 1px #DDDDDD solid;
  // border-radius:4px;
  // height: 32px;
  // background-image: url("../../../assets/add_imgfile.png");
  // background-size:70% 70%;
  // background-position:center center;
  // background-repeat:no-repeat;
  // cursor: pointer;
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
  margin-top:15px ;
}
.titleTop{
  border-bottom: 1px solid #EDEFF2;
  .divSty{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    width: 98%;
    height: 50px;
    line-height: 50px;
  }
}
.specialistManage{
  border: 10px solid #edeff2;
  .expert_header{
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 10px solid #edeff2;
  }
  .expert_table{
    // margin-top: 10px;
    padding: 15px;
    background: white;
  }
}
.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload_File{
  width: 120px;
  border: 1px #DDDDDD solid;
  border-radius:4px;
  height: 32px;
  background-image: url("../../../assets/add_imgfile.png");
  background-size:70% 50%;
  background-position:center center;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin-left: 5px;
}

.upload_gutter2 {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin-left: 5px;
}
.custom-file .input-file {
  display: none;
}

.custom-file .file-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}
 .uploadImg::before {
    content: '选择文件';
    position: absolute;
    right:-50px;
    display: inline-block;
    background: #fff;
    color: white;
    padding: 10px 20px;
    outline: none;
    cursor: pointer;
    border-radius: 4px;
    white-space: nowrap;
}
.menuList{
  margin-top:10px;
}
.menuList ::v-deep .ant-menu-submenu-title {
  background-color: #e6f7ff;
  border-radius: 5px;
  border:1px solid #a0dbff !important;
  span{
    color:#5992d6;
    margin-left:10px
  }
}

.menuList ::v-deep .ant-menu-submenu-title span {
  // span{
    color:#5992d6;
    margin-left:10px
  // }
}
.menuList ::v-deep .ant-menu-submenu-arrow{
    left:18px;
    color:#b8c0cd
  }
.menuList p{
  color:black;
  font-size:13px
}
.menuList ::v-deep .ant-menu-item-group-title{
  padding-left:18px

}

::v-deep .ant-collapse > .ant-collapse-item{
  background-color: #e6f7ff;
  border:1px solid #a0dbff !important;
}
::v-deep .ant-collapse-header{

}
::v-deep .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header{
  color:#5992d6;
  line-height:10px
}
::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow svg{
  color:#b5beca
}
.collapse p{
  font-size:13px;
  color:black
}

.blueTable{
  ::v-deep .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-tbody > tr > td{
  font-weight: bold;
}
  ::v-deep .ant-table-thead > tr > th {
  font-weight: bold;
}
 ::v-deep  .ant-table-small > .ant-table-content > .ant-table-scroll > .ant-table-body > table > .ant-table-thead > tr{
  background-color: #9bc2e6;
 }
}

</style>
